<template>
  <div
    class="drawing-toolbar rounded white d-flex"
  >
    <div
      class="drawing-buttons d-flex"
    >
      <m-tabs
        :value="controller"
        :icon="icons.draw"
        :items="types"
        outlined
        class="drawing-buttons-selector"
        @change="draw"
      />
      <!-- <v-btn
        v-for="(title, key) in types"
        :key="key"
        color="primary"
        small
        text
        tile
        height="100%"
        class="btn"
        @click="draw(key)"
      >
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        {{ title }}
      </v-btn> -->
    </div>
    <v-btn
      v-show="manager!=null"
      text
      small
      color="grey"
      height="100%"
      @click="cancel"
    >
      <v-icon left>{{ icons.close }}</v-icon>
      {{ $t('general.cancel') }}
    </v-btn>
  </div>
</template>

<style scoped>

.drawing-toolbar .btn:not(:last-child) {
  border-right: 1px solid var(--light-border) !important;
}
</style>

<script>
  import { mdiPencil, mdiClose } from '@mdi/js';

  export default {
    props: {
      manager: {
        type: Object,
        default: () => null
      },
      type: {
        type: String,
        default: null
      },
      types: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      icons: {
        draw: mdiPencil,
        close: mdiClose
      },
      controller: null
    }),
    watch: {
      type: {
        immediate: true,
        handler (type) {
          console.log(type);
          this.controller = type;
        }
      }
    },
    methods: {
      draw (type) {
        if (_.isNil(type)) {
          this.cancel();
        }else{
          this.$emit('draw', type);
        }
      },
      cancel () {
        this.$emit('cancel');
      }
    },

    components: {
      MTabs: () => import('@/components/mTabs'),
    }
  }
</script>